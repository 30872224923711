@import "./../../../node_modules/izimodal/css/iziModal.min.css";
@import "./../../../node_modules/flowbite/dist/flowbite.min.css";
@import "styles.css";

#places-map .marker-romania {
    left: 54%;
    top: 24%;
}

.language-style {
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate(12px, 39px) !important;
}

.text p {
    margin-top: 12px !important;
}

.pp {
    display: inline-block;
    vertical-align: top;
    margin: 0;
}

.word {
    position: absolute;
    width: 300px;
    opacity: 0;
}

.letter {
    display: inline-block;
    position: relative;
    float: left;
    transform: translateZ(25px);
    transform-origin: 50% 50% 25px;
}

.letter.out {
    transform: rotateX(90deg);
    transition: transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.letter.behind {
    transform: rotateX(-90deg);
}

.letter.in {
    transform: rotateX(0deg);
    transition: transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.happen {
    margin-left: 10px;
    color: #e40017;
}

.hypen {
    margin-left: 10px;
    color: #ffffff;
}

/** Hour client css**/
.our-clients-section {
    height: 100%;
    width: 100%;
}
.page-home .our-clients-section .title,
.page-home .our-clients-section a {
    width: 20%;
}
.technologies a {
    transition: all 0.5s ease;
}

.technologies a {
    text-align: center;
}
.our-clients-section a:hover {
    background: #fff;
    color: var(--color-primary);
    filter: grayscale(100%);
    z-index: 15;
}
.our-clients-section a:-webkit-any-link {
    text-decoration: none;
}

.our-clients-section a {
    background: #fff;
    float: left;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    margin: 5px;
    /* width: 50% !important; */
}

.our-clients-section a {
    transition: all 0.5s ease;
}

.our-clients-section a {
    text-align: center;
}

.our-clients-section a .info,
.our-clients-section a .img-hypen img {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.our-clients-section a .img-hypen img {
    font-size: 6.8em;
    transition: all 0.5s ease 0.1s;
}

.our-clients-section a .info {
    text-decoration: none;
    width: 100%;
}

.our-clients-section a .info img {
    list-style-type: none;
    padding: 0;
    transform: translateX(150%);
    transition: all 0.5s ease;
}

.our-clients-section a:hover img {
    transform: translateX(-450%) translateY(-50%);
}
.our-clients-section a:hover .info img {
    transform: translateX(-0%) translateY(0%);
}
.page-home .our-clients-section img {
    filter: grayscale(100%);
}
.navbar-button-newsletter {
    align-items: center;
    background-color: #e40017;
    color: #fff !important;
    display: flex !important;
    font-size: 15px;
    font-weight: 600;
    padding: 0 40px !important;
}

.w-unset {
    width: unset;
}

@media (max-width: 915px) {
    .mobile-answer-modal {
        min-width: 90% !important;
    }
    .home-page-work-button {
        min-width: 59% !important;
    }
    .footer-newsletter {
        width: 100% !important;
    }
    .service-height1rem {
        margin-bottom: 0.9rem !important;
    }
    .service-height2rem {
        margin-bottom: 0.9rem !important;
    }
    .service-height3rem {
        margin-bottom: 0.9rem !important;
    }
    .service-height6rem {
        margin-bottom: 0.9rem !important;
    }
    .service-height10rem {
        margin-bottom: 0.9rem !important;
    }
    .service-img {
        display: none !important;
    }
    .mobile-services {
        display: block !important;
        text-align: -webkit-center;
    }
    .desktop-services {
        display: none !important;
    }
}
@media (min-width: 915px) {
    .footer-newsletter {
        width: 43% !important;
    }
    .footer-newsletter2 {
        width: 43% !important;
    }
}
@media (min-width: 1024px) {
    .lg\:ml-\[103px\] {
        margin-left: 103px;
    }
    .lg\:text-left {
        text-align: left;
    }
    .mobile-section3-btn {
        display: none;
    }
}
@media (min-width: 767px) {
    .butonjoinus,
    .mobile-joinus-text,
    .join-us {
        margin-left: 113px;
    }
}
@media (max-width: 767px) {
    .mobile-nav {
        width: 100%;
    }
    .mobile-nav li > a {
        padding: 0;
    }
    .mobile-nav li:last-child {
        float: right;
    }
}
@media (max-width: 472px) {
    .owl-stage-outer .owl-stage .owl-item {
        width: 150px !important;
    }
}
@media (max-width: 1024px) {
    .mobile-technologies-grid {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        margin-bottom: 40px;
    }
    .mobile-technology-card {
        margin: 0 auto !important;
        width: 80% !important;
    }
    .mobile-technology-text {
        order: -1;
        grid-column: span 3;
        width: 100% !important;
        height: fit-content !important;
        margin: 40px 0 20px !important;
    }
    .header-service {
        width: 80%;
    }
    .header-services {
        padding: 0 20px;
    }
    .mobile-logo-footer {
        display: none;
    }
    .desktop-section3-btn {
        display: none;
    }
}

.service-height1rem {
    margin-bottom: 5rem;
}
.service-height2rem {
    margin-bottom: 5rem;
}
.service-height3rem {
    margin-bottom: 8rem;
}
.service-height6rem {
    margin-bottom: 6rem;
}
.mobile-services {
    display: none;
}
.desktop-services {
    display: block;
}
@media screen and (max-height: 575px) {
    #rc-imageselect,
    .g-recaptcha {
        transform: scale(0.77);
        -webkit-transform: scale(0.77);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }
}
@media only screen and (max-width: 500px) {
    .g-recaptcha {
        transform: scale(0.77);
        transform-origin: 0 0;
    }
}
.owl-carousel button {
    display: none !important;
}
.service-activ :hover {
    background: black;
    color: #fff !important;
}
.service-activ h1:hover {
    color: #fff !important;
}
